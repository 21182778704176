
import { defineComponent, onMounted, ref, unref } from 'vue';
import { ElMessageBox, ElMessage, ElForm } from 'element-plus';

import {
  useUsers,
  useDisableUser,
  useEnableUser,
  useSendEmailUser,
  useSendResetUser,
  useBatchCreateUser
} from '@/composables/api';
import { UserIdOptions } from '@/services/api';

import { PartialUser, UserStatus } from '@/interfaces/User';
import SearchBox from '@/components/keyword-searching/Index.vue';
import Filter, { FilterEvent, FilterOption, FilterType } from '@/components/filter/Index.vue';
import permissionUnits from '@/components/permission-units/index.vue';
import useFormErrors from '@/utils/validate';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';


const FILTER_OPTIONS: FilterOption[] = [
  {
    type: FilterType.SELECTOR,
    label: 'Status',
    placeholder: 'Please select an option',
    options: [
      {
        label: 'Enable',
        value: UserStatus.ENABLE
      },
      {
        label: 'Disable',
        value: UserStatus.DISABLE
      }
    ]
  }
];

const DEFAULT_FORM_VALUES: Omit<PartialUser, 'email'> & { emails: string, days: number } = {
  emails: '',
  days: null
};

export default defineComponent({
  components: {
    SearchBox,
    Filter,
    permissionUnits
  },
  setup() {
    const route = useRoute();
    const page = ref(1);
    const keyword = ref();
    const status = ref();
    const sortBy = ref();
    const descSortBy = ref('updated_at');
    const { t } = useI18n();


    const formRef = ref<typeof ElForm>();
    const statusIndex = ref(undefined);

    const { data, isLoading, isFetching, refetch } = useUsers({ page, keyword, status, sortBy, descSortBy });
    const { isLoading: isDisabledLoading, mutate } = useDisableUser();
    const { isLoading: isEnabledLoading, mutate: enableMutate } =
      useEnableUser();
    const { formErrors, bindFormItemError } = useFormErrors();
    const { isLoading: isSendEmailLoading, mutate: sendEmailMutate } =
      useSendEmailUser();
    const { isLoading: isSendResetLoading, mutate: sendResetMutate } =
      useSendResetUser();
    // batch create user
    const { mutate: batchCreate } = useBatchCreateUser();
    const dialogFormVisible = ref(false);
    const formValues = ref(DEFAULT_FORM_VALUES);
    const rules = {
      emails: [
        {
          required: true
        }
      ],
      days: [
        {
          required: true
        }
      ]
    };

    const sortChange = ({ prop, order }) => {
      if (!prop) {
        descSortBy.value = '';
        sortBy.value = '';
        return;
      }

      if (order === 'ascending') {
        sortBy.value = prop;
        descSortBy.value = '';
      } else {
        descSortBy.value = prop;
        sortBy.value = '';
      }
    };

    const searchKeyword = (_keyword: string) => {
      keyword.value = _keyword;
    };
    const handleFilterChange = (event: FilterEvent) => {
      // mutate ref
      status.value = event[0];
    };

    const openDialog = () => {
      dialogFormVisible.value = true;
    };

    const closeDialog = () => {
      dialogFormVisible.value = false;
      formValues.value.emails = '';
      formValues.value.days = null;
    };

    const submitForm = () => {
      formErrors.value = {};
      const form = unref(formRef);

      form && form.validate((valid: boolean) => {
        if (valid) {
          batchCreate(
            { data: formValues.value },
            {
              onSuccess() {
                ElMessage.success({
                  message: 'success!',
                  type: 'success'
                });
                closeDialog();
              },
              onError(error: any) {
                ElMessage.error({
                  message: error.response?.data.message,
                  type: 'error'
                });
                formErrors.value = error.response?.data.errors;
              }
            }
          );
        }
      });
    };

    const disableUser = ({ userId }: UserIdOptions, index: Number) => {
      statusIndex.value = index;
      ElMessageBox.confirm(
        'Are you sure you want to disable user?',
        'Warning',
        {
          confirmButtonText: 'Disable',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }
      )
        .then(() => {
          mutate(
            { userId },
            {
              onSuccess() {
                refetch.value();

                ElMessage({
                  type: 'success',
                  message: 'Disable successfully'
                });
              },
              onError(error: any) {
                ElMessage({
                  message: error.response?.data.message,
                  type: 'error'
                });
              }
            }
          );
        })
        .catch();
    };

    const enableUser = ({ userId }: UserIdOptions, index: Number) => {
      statusIndex.value = index;
      enableMutate(
        { userId },
        {
          onSuccess() {
            refetch.value();

            ElMessage({
              type: 'success',
              message: 'Enable successfully'
            });
          },
          onError(error: any) {
            ElMessage({
              message: error.response?.data.message,
              type: 'error'
            });
          }
        }
      );
    };

    const sendEmailUser = ({ userId }: UserIdOptions, index: Number) => {
      statusIndex.value = index;
      sendEmailMutate(
        { userId },
        {
          onSuccess() {
            refetch.value();

            ElMessage({
              type: 'success',
              message: 'Send successfully'
            });
          },
          onError(error: any) {
            ElMessage({
              message: error.response?.data.message,
              type: 'error'
            });
          }
        }
      );
    };

    const sendResetUser = ({ userId }: UserIdOptions, index: Number) => {
      statusIndex.value = index;
      sendResetMutate(
        { userId },
        {
          onSuccess() {
            refetch.value();

            ElMessage({
              type: 'success',
              message: 'Send successfully'
            });
          },
          onError(error: any) {
            ElMessage({
              message: error.response?.data.message,
              type: 'error'
            });
          }
        }
      );
    };

    onMounted(() => {
      const routeQuery = route.query;

      if (routeQuery.page && (routeQuery.isEdited !== '1')) {
        page.value = Number(route.query.page);
      }
    });

    return {
      page,
      data,
      UserStatus,
      isLoading,
      isFetching,
      isDisabledLoading,
      isEnabledLoading,
      isSendEmailLoading,
      isSendResetLoading,
      statusIndex,
      FILTER_OPTIONS,
      dialogFormVisible,
      formValues,
      rules,
      formRef,
      sortChange,
      openDialog,
      enableUser,
      submitForm,
      closeDialog,
      disableUser,
      sendEmailUser,
      sendResetUser,
      searchKeyword,
      bindFormItemError,
      t,
      handleFilterChange
    };
  }
});
